<template>
  <div class="row">
    <div class="col-12">
      <h2>Escolas</h2>
      <Card>
        <div class="row">
          <div class="col"></div>
          <div
            class="col-md-4 form-row d-flex justify-content-sm-end flex-wrap"
          >
            <router-link to="/schools/new">
              <base-button type="warning add-button">
                Adicionar
              </base-button>
            </router-link>
            <base-input
              type="text"
              v-model="search"
              placeholder="Busca"
              @keyup.enter.native="handleSearch"
            />
          </div>
        </div>
        <SchoolTable :data="schools.data" :removeIndex="removeIndex" />
        <div class="pagination-div">
          <base-pagination
            type="warning"
            :page-count="schools.last_page"
            v-model="current_page"
            @input="handleChangePage"
          />
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Cards/Card'
import SchoolTable from '@/pages/School/SchoolTable'
import { BasePagination } from '@/components'

export default {
  name: 'SchoolPage',
  async created () {
    await this.makePagination(this.current_page)
  },
  data: () => ({
    schools: false,
    current_page: 1,
    search: ''
  }),
  methods: {
    handleSearch () {
      let search = null
      if (this.search) {
        search = this.search
      }

      // reset search
      this.makePagination(1, search)
    },
    handleChangePage (next_page) {
      this.makePagination(next_page)
    },
    async makePagination (page, search = null) {
      let url = `/schools?page=${page}`
      if (search) {
        url += `&search=${search}`
      }

      const {
        data: { data }
      } = await this.$http.get(url)
      this.schools = data
      this.current_page = data.current_page
    },
    removeIndex (id) {
      const schoolIndex = this.schools.data.findIndex(s => s.id === id)
      this.schools.data.splice(schoolIndex, 1)
    }
  },
  components: { Card, SchoolTable, BasePagination },
  watch: {
    current_page () {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style scoped>
.pagination-div {
  display: flex;
  justify-content: flex-end;
}

.add-button {
  height: 40px;
  margin: 0;
  margin-right: 15px;
}
</style>
