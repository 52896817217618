<template>
  <div>
    <base-table :data="data" thead-classes="text-primary">
      <template slot="columns">
        <th>#</th>
        <th>Nome</th>
        <th>Cidade</th>
        <th>Bairro</th>
        <th class="text-right">Status</th>
        <th class="text-right">Ações</th>
      </template>

      <template slot-scope="{ row }">
        <td>{{ row.id }}</td>
        <td>{{ row.name }}</td>
        <td v-if="row.district !== undefined">{{ row.district.city.name }}</td>
        <td v-if="row.district === undefined">--</td>

        <td v-if="row.district !== undefined">{{ row.district.name }}</td>
        <td v-if="row.district === undefined">
          Bairro removido ou nao encontrado
        </td>

        <td class="text-right">
          <span v-if="row.is_active === 1" style="color: green;">
            <i class="tim-icons icon-check-2"></i>
          </span>

          <span v-if="row.is_active === 0" style="color: red;">
            <i class="tim-icons icon-simple-remove"></i>
          </span>
        </td>

        <td class="text-right">
          <el-tooltip
            content="Editar"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <router-link :to="`schools/new/${row.id}`">
              <base-button type="info" icon size="sm" class="btn-link">
                <i class="tim-icons icon-pencil"></i>
              </base-button>
            </router-link>
          </el-tooltip>

          <el-tooltip
            content="Remover"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              type="danger"
              icon
              size="sm"
              class="btn-link"
              @click="removeSchool(row)"
            >
              <i class="tim-icons icon-trash-simple"></i>
            </base-button>
          </el-tooltip>
        </td>
      </template>
    </base-table>
  </div>
</template>
<script>
import { BaseTable } from '@/components'

export default {
  name: 'SchoolTable',
  props: ['data', 'removeIndex'],
  methods: {
    async removeSchool (school) {
      await this.$http.delete(`/schools/${school.id}`)
      this.removeIndex(school.id)
    }
  },
  components: { BaseTable }
}
</script>
